import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "gatsby";
import buttoncontact from "../../assets/jss/material-kit-react/buttoncontact.jsx";
import AniLink from "gatsby-plugin-transition-link/AniLink";


function ButtonContact() {
    return (
      <div>
        {/* <Link className="buttoncustom" to="/location">MORE INFORMATION</Link> */}
        <AniLink className="buttoncontact" paintDrip to="/contact"  hex="#0385BB">
</AniLink>
      </div>
    )
  }

  export default withStyles(buttoncontact)(ButtonContact);